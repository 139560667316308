<template>
    <div :class="wrapperClasses" class="mx-auto max-w-xl transform divide-y overflow-hidden bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all" v-click-outside="handleFocusOut">
        <div class="relative mt-1">
            <form action="/vyhladavanie" method="get" class="w-full relative">
                <button type="submit" class="absolute left-0 w-9 h-full" :class="buttonClasses">
                    <svg xmlns="http://www.w3.org/2000/svg" :class="svgClasses" class="stroke-white" fill="none" viewBox="0 0 24 24" :stroke="hero ? 'gray' : 'white'" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </button>
                <input type="text" name="q" autocomplete="false" @keyup="handleQuery" @focusin="display = true" placeholder="Zadajte názov zariadenia alebo spotrebného materiálu..." :class="inputClasses" class="focus:outline-none">
                <div class="absolute top-1.5 right-2 text-white" v-if="loading">
                    <i class="fas fa-circle-notch fa-spin"></i>
                </div>
            </form>
        </div>

        <ul class="max-h-96 scroll-py-3 overflow-y-auto p-3 w-full" id="options" role="listbox" v-if="autocomplete.length > 0 && display" tabindex="0">
            <a :href="`/produkt/${product.slug}`" v-for="product in autocomplete">
                <li class="group flex select-none rounded p-3 cursor-pointer hover:bg-gray-200" id="option-1" role="option" tabindex="-1">
                    <div class="flex h-10 w-10 flex-none items-center justify-center rounded">
                        <img :src="`https://www.cbcslovakia.sk/${product.main_photo}`" :alt="product.title">
                    </div>
                    <div class="ml-4 flex items-center">
                        <p class="text-sm font-bold">{{ product.title }}</p>
                    </div>
                </li>
            </a>
        </ul>
    </div>
</template>

<script>
import EventBus from '../EventBus'
import axios from "axios";

export default {
    name: 'Autocomplete',
    props: {
        hero: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            autocomplete: [],
            display: false,
            loading: false,
        }
    },
    mounted() {
        EventBus.$on('set-autocomplete', (products) => {
            this.display = true
            this.autocomplete = products
        })
    },
    computed: {
        wrapperClasses() {
            return this.hero ? ['divide-white', 'rounded-3xl', 'w-26r', 'lg:w-32r'] : ['divide-gray-100', 'rounded', 'w-26r']
        },
        buttonClasses() {
            return this.hero ? ['pl-2.5', 'lg:pl-6', 'lg:pr-10', 'border-r', 'border-gray-100'] : ['pl-2.5', 'border-r', 'border-gray-500'];
        },
        svgClasses() {
            return this.hero ? ['h-4', 'w-4', 'lg:h-6', 'lg:w-6'] : ['h-4', 'w-4']
        },
        inputClasses() {
            return this.hero ? ['bg-white', 'text-gray-dark', 'placeholder-gray-500', 'w-full', 'h-8', 'lg:h-14', 'px-2', 'pl-11', 'lg:pl-20', 'text-sm', 'lg:text-base', 'rounded-full'] : ['bg-gray-dark', 'text-white', 'placeholder-gray-200', 'w-full', 'h-8', 'px-2', 'pl-11', 'text-sm', 'rounded'];
        }
    },
    methods: {
        handleQuery(e) {
            console.log(e)

            let timer = null;
            clearTimeout(timer)
            const query = e.target.value || ''

            if (query.length < 3) {
                EventBus.$emit('set-autocomplete', []);
                return false
            }

            timer = setTimeout(async () => {
                this.loading = true
                const response = await axios.get(`/api/autocomplete/${query}`)

                EventBus.$emit('set-autocomplete', response.data.products || [])
                this.loading = false
            }, 300)
        },
        handleFocusOut() {
            this.display = false
        }
    }
}
</script>
