<template>
    <div class="contact_form">
        <h2>Mám záujem o bližšie informácie</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
            <div>
                <div class="form-group">
                    <label>Firma *</label>
                    <div class="input-group">
                        <input type="text" id="company_name" name="company" v-model="form.company"
                               :class="{ 'border-red': errors.includes('company')}" value=""
                               placeholder="Zadajte názov firmy"/>
                    </div>
                </div>
            </div>
            <div>
                <div class="form-group">
                    <label>Meno *</label>
                    <div class="input-group">
                        <input type="text" id="firstname" name="first_name" v-model="form.first_name"
                               :class="{ 'border-red': errors.includes('first_name')}" value=""
                               placeholder="Zadajte svoje meno"/>
                    </div>
                </div>
            </div>
            <div>
                <div class="form-group">
                    <label>Priezvisko *</label>
                    <div class="input-group">
                        <input type="text" id="lastname" name="last_name" v-model="form.last_name"
                               :class="{ 'border-red': errors.includes('last_name')}" value=""
                               placeholder="Zadajte svoje priezvisko"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
            <div>
                <div class="form-group">
                    <label>IČO *</label>
                    <div class="input-group">
                        <input type="text" id="company_registration_number" name="registration_number" v-model="form.registration_number"
                               :class="{ 'border-red': errors.includes('registration_number')}" value=""
                               placeholder="Zadajte IČO firmy"/>
                    </div>
                </div>
            </div>
            <div>
                <div class="form-group">
                    <label>Mobil *</label>
                    <div class="input-group with-prefix">
                        <span class="prefix">+421</span>
                        <input type="text" id="phone" name="phone" v-model="form.phone"
                               :class="{ 'border-red': errors.includes('phone')}" value=""
                               placeholder="Zadajte tel. číslo"/>
                    </div>
                </div>
            </div>
            <div>
                <div class="form-group">
                    <label>E-mail *</label>
                    <div class="input-group">
                        <input type="text" id="email" name="email" v-model="form.email"
                               :class="{ 'border-red': errors.includes('email')}" value=""
                               placeholder="Zadajte e-mail"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mt-6">
            <div class="flex flex-col">
                <h4>Počet zamestnancov</h4>
                <label><input type="radio" name="employees" v-model="form.employees" value="1 - 5"/> 1 - 5</label>
                <label><input type="radio" name="employees" v-model="form.employees" value="5 - 10"/> 6 - 10</label>
                <label><input type="radio" name="employees" v-model="form.employees" value="Viac ako 10"/> Viac ako 10</label>
                <label><input type="radio" name="employees" v-model="form.employees" value="Neviem odpovedať"/> Neviem
                    odpovedať</label>
            </div>
            <div class="flex flex-col">
                <h4>Typ zariadenia</h4>
                <label><input type="radio" name="device_type" v-model="form.device_type" value="Multifunkčné"/>
                    Multifunkčné</label>
                <label><input type="radio" name="device_type" v-model="form.device_type" value="Tlačiareň"/>
                    Tlačiareň</label>
                <label><input type="radio" name="device_type" v-model="form.device_type" value="Neviem odpovedať"/>
                    Neviem odpovedať</label>
            </div>
            <div class="flex flex-col">
                <h4>Farebnosť</h4>
                <label><input type="radio" name="color" v-model="form.color" value="Čiernobiela"/> Čiernobiela</label>
                <label><input type="radio" name="color" v-model="form.color" value="Farebná"/> Farebná</label>
                <label><input type="radio" name="color" v-model="form.color" value="Neviem odpovedať"/> Neviem odpovedať</label>
            </div>
            <div class="flex flex-col">
                <h4>Formát</h4>
                <label><input type="radio" name="form" v-model="form.form" value="A4"/> A4</label>
                <label><input type="radio" name="form" v-model="form.form" value="A3"/> A3, A4</label>
                <label><input type="radio" name="form" v-model="form.form" value="Neviem odpovedať"/> Neviem
                    odpovedať</label>
            </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 mt-4" style="align-items: center;">
            <div>
                <span class="required_fields_note">* Povinné polia</span>
            </div>

            <div class="text-right">
                <button
                    class="bg-green rounded-full mt-2 py-2 px-4 text-sm font-bold text-white hover:bg-green-dark transition"
                    @click="send" v-if="!posted && !loading">
                    Odoslať požiadavku na nezáväznú ponuku
                </button>

                <button class="bg-gray rounded-full mt-2 py-2 px-4 text-sm font-bold text-white"
                        v-if="!posted && loading">
                    <i class="fas fa-spinner fa-spin"></i>
                </button>

                <button id="rent-successful" class="bg-green rounded-full mt-2 py-2 px-4 text-sm font-bold text-white"
                        v-if="posted">
                    Požiadavka bola odoslaná
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'ContactForm',
    data() {
        return {
            errors: [],
            loading: false,
            posted: false,
            form: {
                company: '',
                first_name: '',
                last_name: '',
                registration_number: '',
                phone: '',
                email: '',
                test: false,
                employees: 'Neviem odpovedať',
                device_type: 'Neviem odpovedať',
                color: 'Neviem odpovedať',
                form: 'Neviem odpovedať',
                token: null
            },
        }
    },
    mounted() {
        this.form.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },
    methods: {
        async send() {
            this.loading = true
            this.errors = []

            if (this.form.company === '') {
                this.errors.push('company')
            }

            if (this.form.registration_number === '') {
                this.errors.push('registration_number')
            }

            if (this.form.first_name === '') {
                this.errors.push('first_name')
            }

            if (this.form.last_name === '') {
                this.errors.push('last_name')
            }

            if (this.form.phone === '') {
                this.errors.push('phone')
            }

            if (this.form.email === '') {
                this.errors.push('email')
            }

            if (this.errors.length > 0) {
                this.loading = false
                return true
            }

            setTimeout(async () => {
                const response = await axios.post('/prenajom-kontaktny-formular', {
                    _token: this.token,
                    form: this.form,
                })

                this.loading = false
                this.posted = true
            }, 300)
        }
    }
}
</script>

<style scoped lang="sass">
.contact_form
    background: url("/images/contact_form_bg.png") no-repeat center center
    padding: 4rem
    @media (max-width: 768px)
        background-size: 900%
        padding: 1rem

    *
        color: white

    h2
        text-align: center
        color: white
        margin-top: 0
        font-size: 32px
        line-height: 44px

    input[type=text]
        width: 100%
        background: #FFFFFF
        border: 1px solid #424B54
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05)
        border-radius: 6px
        height: 38px
        line-height: 38px
        padding-left: 10px
        box-sizing: border-box
        color: black

        &.border-red
            border: 2px solid #E11B22

        &.phone-prefix:before
            content: "+421"
            display: inline-block
            line-height: 38px
            color: #424B54
            background: #F9FAFB

    .with-prefix
        position: relative

        span.prefix
            color: #424B54 !important
            background: #F9FAFB
            position: absolute
            left: 3px
            top: 2px
            height: 33px
            padding: 3px 10px 0

        input
            padding-left: 70px

    input[type=radio]
        &:selected
            background: #E11B22
            accent-color: #E11B22

    h4
        font-weight: bold
        font-size: 18px
        color: white

    button
        font-size: 18px
        line-height: 20px
        padding: 15px 40px

    .required_fields_note
        font-style: italic
        @media (max-width: 768px)
            display: block
            text-align: center
</style>
